import { Box, Flex } from "@sqymagma/elements"
import React from "react"
import TrendRow from "./TrendRow"

const TrendsList = ({ content, borderColor, ...props }) => {
  return (
    <Box {...props} py={props.py || 0}>
      <Flex flexWrap="wrap">
        {content.map((trend, idx) => (
          <TrendRow {...trend} key={trend.slug} borderColor={borderColor} first={idx === 0} />
        ))}
      </Flex>
    </Box>
  )
}

export default TrendsList
