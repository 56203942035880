import { Box, Columns } from "@sqymagma/elements"
import React from "react"
import TrendCard from "./TrendCard"

const TrendsGrid = ({ content, cols = 3, ...props }) => {
  return (
    <Box as="section" {...props}>
      <Columns
        hs={{ default: "l", xl: "xl" }}
        vs={{ default: "xl", xl: "4xl", xxl: "5xl" }}
        flexWrap="wrap"
      >
        {content.map(trend => (
          <TrendCard
            {...trend}
            key={trend.slug}
            width={{ xs: 1 / 2, m: 1 / cols }}
            simpleCard={cols > 3}
          />
        ))}
      </Columns>
    </Box>
  )
}

export default TrendsGrid
