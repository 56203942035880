import React from "react"
import { Box, MaxWidth } from "@sqymagma/elements"
import { HeroPattern } from "../IconSystem"

const HeroContainer = ({ children, withDecoration, height, ...props }) => (
  <MaxWidth
    height={height || { default: "500px", m: "640px" }}
    justifyContent="flex-end"
    display="flex"
    flexDirection="column"
    overflowX="hidden"
    {...props}
  >
    {withDecoration && (
      <HeroPattern
        height={{ default: "160px", s: "280px", m: "400px" }}
        position="absolute"
        right={0}
        opacity={0.75}
        top={0}
      />
    )}
    <Box zIndex={1}>{children}</Box>
  </MaxWidth>
)

export default HeroContainer
