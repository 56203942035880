import React from "react"
import { Column, Text, Box } from "@sqymagma/elements"
import Image from "gatsby-image"
import ReadMoreLink from "./ReadMoreLink"
import SiteLink from "./SiteLink"

const TrendCard = ({ title, lead, slug, number, image, imageAlt, simpleCard, ...props }) => {
  return (
    <Column as="article" {...props}>
      <SiteLink to={`/trends/${slug}`} display="block">
        <Box mb="m">
          <Image fluid={image} alt={imageAlt} minHeight={!simpleCard && { default: "200px" }} />
        </Box>
        <Text as="p" textStyle="tinyTightBold" color="text02" mb="xs" caps>
          {`Trend ${number}`}
        </Text>
        <Text
          as="h2"
          textStyle={simpleCard ? "title05" : "title03"}
          mb={!simpleCard && "m"}
          minHeight={!simpleCard && { xs: "56px", m: "96px", l: "72px" }}
        >
          {title}
        </Text>
        {!simpleCard && (
          <>
            <Text
              as="p"
              mb={{ xs: "s", m: "l" }}
              textStyle="bodyTight"
              minHeight={{ default: "72px", xs: "16px", xl: "40px" }}
            >
              {lead}
            </Text>
            <ReadMoreLink as="span" />
          </>
        )}
      </SiteLink>
    </Column>
  )
}

export default TrendCard
