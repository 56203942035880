import React from "react"
import { Box } from "@sqymagma/elements"

const Line = props => (
  <Box
    width={"calc(100% + 64px)"}
    {...props}
    mr={!!props.bleeding && "-32px"}
    ml={!!props.bleeding && "-32px"}
  >
    <Box
      borderColor={props.borderColor}
      css={`
        border-bottom: 1px solid ${p => p.theme.color[`${p.borderColor}`] || p.theme.color.line01};
      `}
    ></Box>
  </Box>
)

export default Line
