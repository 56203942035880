import { Box, Flex, Text } from "@sqymagma/elements"
import BackgroundImage from "gatsby-background-image"
import React from "react"
import Line from "./Line"
import SiteLink from "./SiteLink"

const TrendRow = ({ title, borderColor, slug, number, image, imageAlt, first }) => {
  return (
    <Box as="div" width="100%">
      {!first && <Line pb={{ default: "s", m: "l" }} borderColor={borderColor} />}
      <SiteLink to={`/trends/${slug}`} display="block">
        <Flex as="article" width="100%" justifyContent="space-between">
          <Box width={{ m: 5 / 12 }} pr={{ default: "s", m: "xxl" }}>
            <Text as="p" textStyle="tinyTightBold" color="text02" mb="xs" caps>
              {`Trend ${number}`}
            </Text>

            <Text as="h2" textStyle="title03" color="text05">
              {title}
            </Text>
          </Box>
          <Box mb="m" width={{ default: "100px", m: 7 / 12 }}>
            <BackgroundImage fluid={image} alt={imageAlt}>
              <Box
                width={{ default: "80px", xs: "100px", m: "100%" }}
                height={{ default: "80px", xs: "100px", m: "200px" }}
              />
            </BackgroundImage>
          </Box>
        </Flex>
      </SiteLink>
    </Box>
  )
}

export default TrendRow
