import { Box } from "@sqymagma/elements"
import React from "react"
import TrendsGrid from "../components/TrendsGrid"
import TrendsList from "../components/TrendsList"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const TrendsViewer = ({ bgColor, cols, content, ...props }) => {
  const breakpoints = useBreakpoint()

  return breakpoints.s ? (
    <Box bg={bgColor} py={props.py || { default: "xl", m: "4xl", l: "5xl" }}>
      <TrendsGrid content={content} cols={cols} {...props} />
    </Box>
  ) : (
    <Box bg={bgColor} py={props.py || { default: "xl", m: "4xl" }}>
      <TrendsList content={content} py="l" borderColor="line03" cols={cols} {...props} />
    </Box>
  )
}

export default TrendsViewer
